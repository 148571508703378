// 详情页面
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 获取项目经理/技术负责人详情页数据
export const searchPersonAllByName = (data) => {
  return request({
    url: '/api/result/searchPersonAllByName',
    method: "post",
    data
  })
}

// 获取公司的资质列表
export const searchCertDetail = (params) => {
  return request({
    url: '/api/cert/searchCertDetail',
    method: "get",
    params
  })
}

// 获取招标详情
export const getNoticeDetail = (params) => {
  return request({
    url: '/api/notice/detail',
    method: "get",
    params
  })
}

// 获取pdf文件地址
export const getPdfUrl = (params) => {
  return request({
    url: '/api/oss/getUrlById',
    method: "get",
    params
  })
}